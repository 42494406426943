<template>
  <div style="margin: 10px 0">
    <div>
      <div class="cell-input">
        <el-input placeholder="Name" v-model="call" type="text" clearable> </el-input>
        <!-- <input class="cont-input" v-model="call" placeholder="如何称呼您" type="text"> -->
      </div>
      <div class="cell-input">
        <el-input placeholder="Company" v-model="company" type="text" clearable>
        </el-input>
        <!-- <input class="cont-input" placeholder="公司名称" type="text"> -->
      </div>
      <div class="cell-input">
        <el-input
          placeholder="Country/Region, Province/State, City"
          v-model="location"
          type="text"
          clearable
        >
        </el-input>
        <!-- <input class="cont-input" placeholder="国家/地区、省份、城市" type="text"> -->
      </div>
      <div class="cell-input">
        <el-input placeholder="Phone Number" v-model="phone" type="tel" clearable>
        </el-input>
        <!-- <input class="cont-input" placeholder="联系电话" type="tel"> -->
      </div>
      <div class="cell-input">
        <el-input placeholder="E-mail" v-model="email" type="email" clearable> </el-input>
        <!-- <input class="cont-input" placeholder="电子邮箱" type="email"> -->
      </div>
      <div class="cell-input">
        <!-- <el-select v-model="type" clearable placeholder="Inquiry Type">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <select v-model="type" class="select-input" name="skills" id="skills"  @change="getvaluemethod($event)">
          <option value="" hidden>Inquiry Type</option>
          <option v-for="item in options" :key="item.value" :value="item.value">{{ item.label }}</option>
        </select>
      </div>
      <div class="cell-passage">
        <textarea
          class="textarea"
          v-model="demand"
          name=""
          placeholder="Detailed Requirements"
          id=""
          cols="30"
          rows="10"
        ></textarea>
      </div>
    </div>
    <div class="btn">
      <el-button class="submit" @click="submit">Submit</el-button>
    </div>
  </div>
</template>

<script>
import { addData } from "@/utils/http.js";
export default {
  data() {
    return {
      call: "",
      company: "",
      location: "",
      phone: "",
      email: "",
      options: [
        {
          value: "buy",
          label: "Pre-Sales Inquiry",
        },
        {
          value: "plan",
          label: "Solution",
        },
        {
          value: "after",
          label: "After-Sales Support",
        },
      ],
      type: "",
      demand: "",
    };
  },
  methods: {
    getvaluemethod(event){
      this.type = event.target.value
    },
    async submit() {
      let that = this;
      console.log(that.type)
      let entire =
        that.call.length == 0 ||
        that.company.length == 0 ||
        that.location.length == 0 ||
        that.phone.length == 0 ||
        that.type.length == 0;
      if (!entire) {
        let now = new Date();
        let year = now.getFullYear();
        let month = ("0" + (now.getMonth() + 1)).slice(-2);
        let day = ("0" + now.getDate()).slice(-2);
        let hours = ("0" + now.getHours()).slice(-2);
        let minutes = ("0" + now.getMinutes()).slice(-2);
        let seconds = ("0" + now.getSeconds()).slice(-2);
        let nowTime =
          year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        // 数据库新增一条消息
        await addData("userInquire-en", {
          call: that.call,
          company: that.company,
          dz: that.location,
          phone: that.phone,
          email: that.email,
          type: that.type,
          xq: that.demand,
          time: nowTime,
        })
          .then(() => {
            that.$message({
              message: "Submitted Successfully",
              type: "success",
            });
            that.call = "";
            that.company = "";
            that.location = "";
            that.phone = "";
            that.email = "";
            that.type = "";
            that.demand = "";
          })
          .catch(() => {
            that.$message.error("Submission Failed! Please Try Again Later!");
          });
        return;
      } else {
        this.$message({
          message: "Please Provide Complete Information",
          type: "warning",
        });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
  height: 100%;
}

::v-deep .el-input--suffix {
  width: 100%;
  height: 100%;

  .el-input__inner {
    height: 100%;
    background-color: transparent;
    border: none;
    padding-left: 0;
    font-family: "Alibaba PuHuiTi R", sans-serif;
    font-size: 15px;
    color: rgba(1, 12, 33, 1);

    &::placeholder {
      color: rgba(1, 12, 33, 0.5);
    }
  }
}
::v-deep .el-select .el-input .el-select__caret {
  color: var(--main-bg-color);
}
.cell-input {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(190, 190, 190, 0.5);
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 0 10px;
  font-family: "Alibaba PuHuiTi R", sans-serif;
  font-size: 16px;
  color: rgba(1, 12, 33, 0.5);
}
.textarea {
  width: 100%;
  height: 100px;
  background: transparent;
  outline: none;
  font-family: "Alibaba PuHuiTi R", sans-serif;
  font-size: 16px;
  color: rgba(1, 12, 33, 1);
  resize: none;
  border: 1px solid rgba(190, 190, 190, 0.5);
  border-radius: 10px;
  padding: 10px;

  &::placeholder {
    color: rgba(1, 12, 33, 0.5);
  }
}
.btn {
  margin-top: 10px;
  text-align: center;
}
.submit {
  width: 80%;
  height: 40px;
  background: var(--main-one-color);
  border-radius: 20px;
  border: none;
  font-family: "Alibaba PuHuiTi B", sans-serif;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.select-input{
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-family: "Alibaba PuHuiTi R", sans-serif;
  font-size: 16px;
}
</style>
